<template>
  <div class="suggested_item_list">
    <div
      v-for="item in items"
      :key="item.item_id"
      class="suggested_item_card_container"
    >
      <div
        class="item-card"
        @click="selectItem(item.item_id)"
      >
        <img
          :src="
            ROOT_IMAGE_URL
              + '/'
              + genre
              + '/'
              + item.item_id
              + '.jpg'
          "
          class="item_img"
        >
        <div class="item_brief_container">
          <div class="item_brief">
            <p class="item_brief_brandName">
              {{ item.brand_name }}
            </p>
            <p class="item_brief_itemName">
              {{ item.item_name }}
            </p>
            <div>
              <div class="item_brief_priceArea_container">
                <div 
                  v-if="item.official_price > 0"
                  class="item_brief_priceArea"
                >
                  <span>
                    小売希望価格:&nbsp;¥{{ item.official_price }}
                  </span>
                </div>
                <div
                  v-else
                  class="item_brief_priceArea"
                >
                  <span>
                    小売希望価格:&nbsp;-
                  </span>
                </div>
              </div>
              <div class="item_brief_otherConciergeIcon_container">
                <div
                  v-for="other_concierge in item.other_concierges"
                  :key="other_concierge"
                  class="item_brief_otherConciergeIcon"
                >
                  <img
                    :src="
                      ROOT_IMAGE_URL +
                        '/face-icon/' +
                        convertNameToRoman(other_concierge) +
                        '.png'
                    "
                  >
                </div>
              </div>
              <img
                v-if="selectedItemId==item.item_id" 
                src="@/assets/logos/up_arrow.svg"
                class="select-arrow"
              >
              <img v-else
                src="@/assets/logos/down_arrow.svg"
                class="select-arrow"
              >
            </div>
          </div>
        </div>
        <div :class="{selected: selectedItemId==item.item_id}"/>
      </div>
      <item-detail
        v-show="selectedItemId==item.item_id"
        :category="category"
        :genre="genre"
        :item="item"
        :official_price="item.official_price"
      />
    </div>
  </div>
</template>
<script>
import ItemDetail from "./ItemDetail.vue"
export default {
  name: 'ItemList',
  components: {
    ItemDetail
  },
  props: {
    genre: {
      type: String
    },
    category: {
      type: String
    },
    items: {
      type: Object
    }
  },
  data(){
    return {
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      selectedItemId: 0,
      itemIds: []
    }
  },
  updated(){
    this.itemIds = this.items.map(x => x.item_id)
  },
  methods: {
    convertNameToRoman(name) {
      if (name == 'ぺき' || name == 'ペキ') {
        return 'peki'
      }
      return name.toLowerCase()
    },
    selectItem(item_id){
      if(this.selectedItemId == item_id){
        this.selectedItemId =0
        return
      }else{
        if(this.itemIds.indexOf(this.selectedItemId)!=-1&&this.selectedItemId!=0 && this.itemIds.indexOf(this.selectedItemId) < this.itemIds.indexOf(item_id)){
          const currentVisibleDetail = document.getElementsByClassName("suggested_item__component")[this.itemIds.indexOf(this.selectedItemId)]
          const currentVisibleDetailHeight = currentVisibleDetail.clientHeight
          window.scrollBy(0, -currentVisibleDetailHeight)
          this.selectedItemId = item_id
        }else{
          this.selectedItemId = item_id
        }
        this.$gtag.event('view_item', this.convertItem2GA4(item_id))
        return
      }
    },
    convertItem2GA4(item_id) {
      const item = this.items.find( item_ => item_.item_id==item_id)
      return {
        currency: "JPY",
        value:
          item.affiliate_links.amazon != "" || item.affiliate_links != ""
            ? item.official_price
            : 0,
        items: [
          {
            item_id: item.item_id,
            item_name: item.item_name,
            item_brand: item.brand_name,
            item_category: this.genre,
            item_category2: this.cagtegory,
            affiliation: this.genre == "makeup" ? "Eri" : item.has_main_concierge
              ? this.concierge_name
              : "(" + item.other_concierges.join(",") + ")",
          }
        ]
      }
    },
  }
}
</script>
<style scoped>
.suggested_item_list{
  background-color: #fff;
}
/* .suggested_item_card_container{
  padding: 0 5px;
} */
.item-card {
  width: 100%;
  display: flex;
  position: relative;
}
.item-card:last-of-type{
  margin-bottom: 5px;
}
.item_img {
  width: 30%;
}
.item_brief_container{
  width: 70%;
  padding-left: 10px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.item_brief{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item_brief_brandName{
  font-size: 14px;
  padding-bottom: 5px;
}
.item_brief_itemName{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7.5px;
}
.item_brief_priceArea_container{
  font-size: 14px;
  display: inline-block;
  /* color: #F29F8F; */
}
.item_brief_priceArea{
  display: inline-block;
  padding-right: 15px;
}
.item_brief_priceArea img{
  width: 14px;
}
.item_brief_otherConciergeIcon_container{
  display: inline-block;
}
.item_brief_otherConciergeIcon{
  display: inline-block;
}
.item_brief_otherConciergeIcon_container img{
  width: 25px;
  border: solid 1px #CCCCCC;
  border-radius: 50%;
  margin-right: 3px;
}
/* .selected{
  background-color: grey;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
} */
.select-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 10px;
}
</style>