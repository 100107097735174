<template>
  <section class="suggested_item__component">
    <!-- スキンケア商品 -->
    <div v-if="genre==='skin-care'">
      <div class="suggested_item_info__component">
        <div class="item_img_container">
          <img
            class="item_img"
            :src="
              ROOT_IMAGE_URL
                + '/'
                + genre
                + '/'
                + item.item_id
                + '.jpg'
            "
          >
        </div>
        <div class="item_brand">
          {{ item.brand_name }}
        </div>
        <h2 class="item_name">
          {{ item.item_name }}
        </h2>
        <div
          v-if="!isAffiliateUrlBlank(item.affiliate_links)"
          class="item_purchase"
        >
          <div
            v-if="checkAmazonURL(item.affiliate_links)"
            class="amazon"
          >
            <img src="@/assets/logos/amazon_logo.svg">
            <div v-if="amazonPrice === 0">
              <span>
                -
              </span>
            </div>
            <div v-else>
              <span>
                ¥{{ amazonPrice }}
              </span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.amazon}`"
              rel="nofollow"
              referrerpolicy="no-referrer-when-downgrade"
            >
              最安値を見る
            </a>
          </div>
          <div
            v-if="checkRakutenURL(item.affiliate_links)"
            class="rakuten"
          >
            <img src="@/assets/logos/rakuten_logo.svg">
            <div v-if="rakutenPrice === 0">
              <span>
                -
              </span>
            </div>
            <div v-else>
              <span>
                ¥{{ rakutenPrice }}
              </span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.rakuten}`"
              rel="nofollow"
            >
              最安値を見る
            </a>
          </div>
          <span class="item_price_attention">
            *&nbsp;表示されている価格が正確ではない可能性がありますことをご留意ください。
          </span>
        </div>
        <div
          v-if="isAffiliateUrlBlank(item.affiliate_links)"
          class="item_officialPage_btn"
        >
          <a
            :href="`${item.official_url}`"
          >
            ▼&nbsp;公式ページへ
          </a>
        </div>
        <div class="item_additionalInfo_container">
          <div class="item_additionalInfo">
            <div
              v-if="item.comment"
              class="item_comment_container"
            >
              <div class="item_comment_title">
                <p>-&nbsp;コンシェルジュのコメント&nbsp;-</p>
              </div>
              <div class="item_comment">
                <div
                  v-if="!isCommentOpen"
                  class="shortened_comment"
                >
                  <p>
                    {{ shortened_comment }}
                  </p>
                  <p
                    class="see_more"
                    @click="commentOpen"
                  >
                    ▼&nbsp;もっと見る
                  </p>
                </div>
                <div
                  v-else
                  class="full_comment"
                >
                  <p>
                    {{ item.comment }}
                  </p>
                  <p
                    class="see_more"
                    @click="commentClose"
                  >
                    ▲&nbsp;とじる
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="'other_concierges' in item && item.other_concierges.length > 0"
              class="concierges_info_container"
            >
              <div>
                <p>
                  他のコンシェルジュもおすすめしています!
                </p>
              </div>
            </div>
            <div class="concierges_info_inner">
              <div
                v-for="other_concierge in item.other_concierges"
                :key="other_concierge"
              >
                <div class="concierge_other">
                  <router-link
                    :to="
                      {
                        name: 'Concierges',
                        params: {
                          'incomingGenre': 'skincare',
                          'incomingConcierge': convertNameToRoman(other_concierge)
                        }
                      }
                    "
                  >
                    <img
                      :src="
                        ROOT_IMAGE_URL +
                          '/face-icon/' +
                          convertNameToRoman(other_concierge) +
                          '.png'
                      "
                    >
                    <p>{{ other_concierge }}</p>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="item_detailInfo_container">
              <h4>商品詳細</h4>
              <div class="item_detailInfo">
                <ul>
                  <li><p>カテゴリー</p>
                    <span>
                      {{ category }}
                    </span>
                  </li>
                  <li>
                    <p>ブランド</p>
                    <span>
                      {{ item.brand_name }}
                    </span>
                  </li>
                  <li>
                    <p>商品名</p>
                    <span>
                      {{ item.item_name }}
                    </span>
                  </li>
                  <li>
                    <p>小売希望価格</p>
                    <span v-if="official_price > 0">
                      ¥{{ official_price }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- メイク商品 -->
    <div v-if="genre==='makeup'">
      <div class="suggested_item_info__component">
        <img
          class="item_img"
          :src="
            ROOT_IMAGE_URL
              + '/'
              + genre
              + '/'
              + item.item_id
              + '.jpg'
          "
        >
        <div class="item_brand">
          {{ item.brand_name }}
        </div>
        <h2 class="item_name">
          {{ item.item_name }}
        </h2>
        <div
          v-if="!isAffiliateUrlBlank(item.affiliate_links)"
          class="item_purchase"
        >
          <div
            v-if="checkAmazonURL(item.affiliate_links)"
            class="amazon"
          >
            <img src="@/assets/logos/amazon_logo.svg">
            <div v-if="amazonPrice === 0">
              <span>
                -
              </span>
            </div>
            <div v-else>
              <span>
                ¥{{ amazonPrice }}
              </span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.amazon}`"
              rel="nofollow"
              referrerpolicy="no-referrer-when-downgrade"
            >
              最安値を見る
            </a>
          </div>
          <div
            v-if="checkRakutenURL(item.affiliate_links)"
            class="rakuten"
          >
            <img src="@/assets/logos/rakuten_logo.svg">
            <div v-if="rakutenPrice === 0">
              <span>
                -
              </span>
            </div>
            <div v-else>
              <span>
                ¥{{ rakutenPrice }}
              </span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.rakuten}`"
              rel="nofollow"
            >
              最安値を見る
            </a>
          </div>
          <span class="item_price_attention">
            *&nbsp;表示されている価格が正確ではない可能性がありますことをご留意ください。
          </span>
        </div>
        <div
          v-if="isAffiliateUrlBlank(item.affiliate_links)"
          class="item_officialPage_btn"
        >
          <a
            :href="`${item.official_url}`"
          >
            ▼&nbsp;公式ページへ
          </a>
        </div>
        <div class="item_additionalInfo_container">
          <div class="item_additionalInfo">
            <div
              v-show="item.tools.length > 0"
              class="makeupTools_container"
            >
              <h4>使用したメイク道具</h4>
              <div class="makeupTools_inner">
                <div
                  v-for="(tool, i) in item.tools"
                  :key="i"
                  class="makeupTools"
                >
                  <img
                    :src="
                      ROOT_IMAGE_URL
                        + '/makeup-tool/'
                        + tool.item_id
                        + '.jpg'
                    "
                    class="makeupTools_img"
                    @click="selectTool(tool)"
                  >
                </div>
              </div>
            </div>
            <div class="item_detailInfo_container">
              <h4>商品詳細</h4>
              <div class="item_detailInfo">
                <ul>
                  <li><p>カテゴリー</p>
                    <span>
                      {{ category }}
                    </span>
                  </li>
                  <li>
                    <p>ブランド</p>
                    <span>
                      {{ item.brand_name }}
                    </span>
                  </li>
                  <li>
                    <p>商品名</p>
                    <span>
                      {{ item.item_name }}
                    </span>
                  </li>
                  <li>
                    <p>小売希望価格</p>
                    <span v-if="official_price > 0">
                      ¥{{ official_price }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- メイク道具 -->
      <make-tool 
        v-if="isOpen"
        :tool="selectedTool"
        :isOpen="isOpen"
        @modalClose="modalClose"
      />
    </div>
  </section>
</template>
<script>
import axios from "axios"
import MakeTool from "@/components/MakeTool.vue"

export default {
  name: "ItemDetail",
  components: {
    MakeTool
  },
  props: {
    category: {
      type: String
    },
    item: {
      type: Object,
    },
		genre: {
			type: String
		},
		official_price: {
			type: Number
		},
  },
	data(){
		return {
			ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
			isCommentOpen: false,
      shortCommentHeight: 0,
      fullCommentHeight: 0,
      // モーダル部分
      isOpen: false,
      selectedTool: null,
      amazonPrice: 0,
      rakutenPrice: 0,
      price_data : {}
		}
	},
  computed:{
		genreName(){
			if(this.genre=="makeup"){
				return "メイク"
			}else{
				return "スキンケア"
			}
		},
		shortened_comment(){
			const MAX_LENGTH = 50
			return this.item.comment.substr(0, MAX_LENGTH) + "..."
		},
	},
  mounted(){
    this.setPriceByItemId(this.item.item_id)
  },
	methods: {
    convertNameToRoman(name) {
      if (name == 'ぺき' || name == 'ペキ') {
        return 'peki'
      }
      return name.toLowerCase()
    },
    checkAmazonURL(affiliateUrl) {
      return 'amazon' in affiliateUrl && affiliateUrl.amazon !== ''
    },
    checkRakutenURL(affiliateUrl) {
      return 'rakuten' in affiliateUrl && affiliateUrl.rakuten !== ''
    },
    commentOpen(){
      const shortCommentElm = document.getElementsByClassName("shortened_comment")[0]
      this.shortCommentHeight = shortCommentElm.clientHeight
      this.isCommentOpen = true
    },
    commentClose(){
      const fullCommentElm = document.getElementsByClassName("full_comment")[0]
      this.fullCommentHeight = fullCommentElm.clientHeight
      this.isCommentOpen = false
      window.scrollBy(0, this.shortCommentHeight-this.fullCommentHeight)
    },
		isAffiliateUrlBlank(affiliateUrls) {
      if (
        (!('amazon' in affiliateUrls) || affiliateUrls.amazon === '') &&
        (!('rakuten' in affiliateUrls) || affiliateUrls.rakuten === '')
      ) {
        return true
      }
      else {
        return false
      }
		},
    selectTool(tool, i) {
      this.selectedTool = tool
      this.isOpen = true
    },
    setPriceByItemId(itemId) {
      axios
        .post("https://api.dev.yuni-cosme.com/v1/price/", {
          item_id: itemId
        })
        .then((res) => {
          this.amazonPrice = "amazon" in res.data ? res.data.amazon.price : 0,
          this.rakutenPrice = "rakuten" in res.data ? res.data.rakuten.price : 0
        })
        .catch((err) => {
          console.log("ItemDetail", err.code, err.message);
        })
    },
    modalClose(modalState) {
      this.isOpen = modalState
    }
	}
};
</script>
<style scoped>
.suggested_item__component {
  padding: 0 40px;
  background-color: #ffffff;
}
.suggested_item_info__component {
  padding-bottom: 30px;
}
.item_category {
  display: inline-block;
}
.item_img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.item_brand{
  font-size: 14px;
  padding-bottom: 5px;
}
.item_name {
  font-size: 20px;
  padding-bottom: 12px;
  font-weight: bold;
}
.item_purchase {
  padding-bottom: 43px;
}
.amazon, .rakuten{
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  align-self: center;
}
.amazon img, .rakuten img{
  width: 24px;
}
.amazon span, .rakuten span{
  font-size: 18px;
  font-weight: bold;
  color: #F29F8F;
  display: inline-block;
  text-align: right;
  width: 80px;
  margin-right: 10px;
}
/* .item_price{
  margin-left: 15px;
} */

.item_price {
  font-family: "Brandon Grotesque";
  font-size: 24px;
}
.purchase_btn {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 12px;
  color: #ffffff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_officialPage_btn{
  text-align: center;
  padding-bottom: 45px;
}
.item_officialPage_btn a{
  font-size: 16px;
  font-weight: bold;
  padding: 10px 18px;
  color: #ffffff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_price_attention{
  display: block;
  padding-top: 3px;
  font-size: 12px;
}
/* メイク動画・メイク道具・商品詳細 */
.item_additionalInfo_container{
  background-color: #F5EAE7;
  padding: 0 20px;
}
.item_additionalInfo{
  padding: 30px 0;
}
.concierges_info_container{
  padding-top: 50px;
}
.concierges_info_container p{
  font-size: 14px;
  color: #777777;
  padding-bottom: 20px;
}
.howToMakeup_container h4,.makeupTools_container h4, .makeupTools_modal_inner h4, .item_detailInfo_container h4{
  font-size: 14px;
  color: #777777;
  display: inline-block;
  z-index: 10;
  /* box-shadow: 10px 10px #FADAD2; */
}
.howToMakeup_container iframe{
  padding:29px 0 94px 0;
}
.makeupTools_inner{
  display: flex;
  min-width: max-content;
}
.makeupTools{
  padding-top: 20px;
}
.makeupTools_img{
  width: 65px;
  border: #CCCCCC solid 1px;
  border-radius: 50%;
  margin-right: 13px;
  /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
}
.item_detailInfo_container{
  padding-top: 50px;
}
.item_detailInfo{
  color: #777777;
  font-size: 12px;
  padding-top: 20px;
}
.item_detailInfo li{
  display: flex;
  padding-bottom: 14px;
}
.item_detailInfo p{
  display: inline-block;
  width: 30%;
}
.item_detailInfo span{
  padding-left: 20px;
  width: 70%;
}
/* モーダル部分 */
.makeupTools_modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
}
.makeupTools_modal_container {
  position: relative;
  background-color: #fff;
  padding: 0 40px;
}
.makeupTools_modal_inner{
  padding: 25px 0;
}
.makeupTools_modal_close {
  float: right;
  display: block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.makeupTools_modal_close_container {
  position: relative;
  width: 40px;
}
.makeupTools_modal_close_border_top {
  background-color: #707070;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  top:-6px;
  transform: translateY(6px) rotate(-20deg);
}
.makeupTools_modal_close_border_bottom {
  background-color: #707070;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  bottom: -8px;
  transform: translateY(-6px) rotate(20deg);
}
.modal_item_img{
  width: 100%;
  object-fit: cover;
  padding: 25px 0;
}
.grey_screen{
  position: relative;
  pointer-events: none;
}
.grey_screen::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color:rgba(0, 0, 0, 0.4);
  z-index: 100;
  right: 0px;
  pointer-events: none;
}
.button_disabled{
  pointer-events: none;
}
.item_comment_title {
  text-align: center;
}
.item_comment_title p {
  font-size: 17px;
}
.item_comment {
  padding-top: 30px;
  text-align: center;
}
.item_comment p{
  text-align: left;
}
.item_comment_btn {
  text-align: center;
}
.item_comment_btn p {
  display: inline-block;
  padding: 11px 17px;
  border: #f29f8f solid 1px;
}
.see_more{
  padding: 10px 16px;
  border: solid 1px #F29F8F;
  margin-top: 20px;
  display: inline-block;
}
/* .concierges_info_container p{
  font-size: 18px;
  color: #222222;
  padding: 50px 0 20px 0;
} */
.concierges_info_inner {
  display: flex;
}
.concierges_info_inner p {
  font-size: 18px;
  padding-bottom: 20px;
}
.concierge_other {
  width: 100%;
  padding-right: 30px;
}
.concierge_other > * + * {
  margin-left: 20px;
}
.concierge_other a{
  display: inline-block;
}
.concierge_other img {
  width: 60px;
  border-radius: 50%;
}
.concierge_other p{
  font-size: 12px;
  padding: 5px 0 0 0;
  margin: 0;
  text-align: center;
  color: #222222;
}
</style>